@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* data-radix-popper-content-wrapper */
 /* {
  z-index: 1000 !important;
} */

div[data-radix-popper-content-wrapper] {
  z-index: 1000 !important;
  background-color: white !important;
}

.card {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h2 {
    margin-top: 0;
    color: #333;
}

.card .number {
    font-size: 36px;
    font-weight: bold;
    color: #007bff;
    margin-top: 10px;
}

.card .number.loading,
.card .number.error {
    font-size: 24px;
    color: #666;
    font-style: italic;
}